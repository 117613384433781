export const en = {
    menu: {
        about: "About",
        privacy: "Privacy",
        terms: "Terms",
        contactUs: "Contact Us",
        ENKRIPWeb: "{{APP_NAME}} Web"
    },
    genericContent:{
        email: "Email",
        phoneNumber: "Phone Number",
        mailingAddress: "Mailing Address",
        joinEnkrip: "Join {{APP_NAME}}"
    },
    pages: {
        home: {
            mainBanner:{
                title: "Connect with Friends & Family",
                desc: "Stay connected with those who matter most, no matter where life takes you. Chat, video call, and share your moments effortlessly.",
                cardSection1:{
                    title: "Empowering People to Build Communities",
                    desc: "Our app is dedicated to fostering safe, inclusive spaces where everyone can connect, share, and thrive."
                },
                cardSection2: {
                    title: "Feel Like You're Right There",
                    desc: "Whether through text, voice, or video, stay close to the people who matter on both iOS and Android."
                },
                cardSection3: {
                    title: "Share Confidently with End-to-End Encryption",
                    desc: "Communicate openly and securely, knowing your conversations are protected by industry-leading end-to-end encryption."
                }
            },
            banner1:{
                title: "Never Miss a Moment with Voice and Video Calls",
                desc: "From group calls with friends to quick check-ins with family, enjoy crystal-clear voice and video that make you feel like you're in the same room."
            },
            banner2:{
                title: "Explore Engaging Content",
                desc: "Dive into a world of photos, videos, and profiles tailored to your interests. Discover and connect with new content effortlessly."
            },
            banner3:{
                title: "Capture and Share Your Everyday Moments",
                desc: "From a group call to classmates to a quick call with mom, feel like you're in the same room with voice and video calls."
            }
        },
        about: {
            title: "About {{APP_NAME}}",
            para1: "At {{APP_NAME}}, we're dedicated to bringing people closer together by empowering them to build vibrant, secure communities. Our app is more than just a communication tool—it's a platform designed to make staying connected with friends, family, and like-minded individuals effortless and meaningful.",
            para2: "With a commitment to privacy and security, {{APP_NAME}} ensures that your conversations and interactions remain private through cutting-edge end-to-end encryption. Whether you're sharing life's moments, making plans with a group, or simply catching up with someone special, {{APP_NAME}} makes it all possible.",
            para3: "We believe in creating a safe, inclusive space where everyone can connect, share, and thrive. Available on both iOS and Android, our app is designed to make you feel like you're right there with the people who matter most, no matter where they are."
        },
        privacy: {
            title: "{{APP_NAME}} Privacy Policy",
            para1: "Welcome to {{APP_NAME}}! Your privacy is important to us. This Privacy Policy explains how PT. Cahaya Sinergi Sentosa (“we”, “us”, “our”) collects, uses, discloses, and protects your information when you use our social media and chat application, {{APP_NAME}} (“App”). By using {{APP_NAME}}, you agree to the collection and use of information in accordance with this policy.",
            subtitle1:{
                title: "1. Information We Collect",
                subtitle1:{
                    title: "1.1 Personal Information",
                    para1: "When you register and use our App, we may collect the following personal information:",
                    para2: {
                        label: "Name:",
                        desc: "To identify and personalize your account."
                    },
                    para3: {
                        label: "Email address:",
                        desc: "To communicate with you, send updates, and verify your account."
                    },
                    para4: {
                        label: "Phone number:",
                        desc: "To connect you with your contacts and for security purposes."
                    },
                    para5: {
                        label: "Profile picture:",
                        desc: "To personalize your account."
                    },
                    para6: {
                        label: "Contacts:",
                        desc: "With your consent, we may access your contact list to help you connect with friends who also use {{APP_NAME}}."
                    }
                },
                subtitle2: {
                    title: "1.2 Content",
                    para1: "We collect the content you create, share, and communicate through the App, including:",
                    para2:{
                        label: "Photos and videos:",
                        desc: "Visual content you upload or share."
                    },
                    para3:{
                        label: "Messages and chat history:",
                        desc: "Your communication with other users."
                    },
                    para4:{
                        label: "Comments and likes:",
                        desc: "Your interactions with other users’ content."
                    },
                },
                subtitle3: {
                    title: "1.3 Usage Information",
                    para1: "We collect information about your interactions with the App, such as:",
                    para2:{
                        label: "Date and time of access:",
                        desc: "To track app usage and improve user experience."
                    },
                    para3:{
                        label: "Pages viewed and features used:",
                        desc: "To understand how you use the App and optimize our services."
                    },
                    para4:{
                        label: "Links clicked:",
                        desc: "To analyze your preferences and interests."
                    },
                    para5:{
                        label: "IP address:",
                        desc: "To identify your general location and enhance security."
                    },
                    para6:{
                        label: "Device information:",
                        desc: "Such as device ID, operating system, browser type, to ensure compatibility and optimal performance."
                    },
                }
            },
            subtitle2:{
                title: "2. How We Use Your Information",
                para1: "We use the information we collect for the following purposes:",
                para2:{
                    label: "To provide, maintain, and improve the App:",
                    desc: "Ensuring the App functions properly and fixing technical issues."
                },
                para3:{
                    label: "To personalize your experience:",
                    desc: "Presenting relevant content and recommendations."
                },
                para4:{
                    label: "To communicate with you:",
                    desc: "Sending updates, news, and promotional materials related to {{APP_NAME}}."
                },
                para5:{
                    label: "To analyze usage and trends:",
                    desc: "Understanding how users interact with the App and conducting research to improve our services."
                },
                para6:{
                    label: "To ensure security and prevent misuse:",
                    desc: "Protecting our users and maintaining the integrity of the App."
                }
            },
            subtitle3:{
                title: "3. Sharing Your Information",
                para1: "We may share your information with third parties under the following circumstances:",
                para2:{
                    label: "With your consent:",
                    desc: "We will request your consent before sharing your personal information with third parties."
                },
                para3:{
                    label: "With service providers:",
                    desc: "We work with service providers who assist us in operating the App, such as cloud storage, analytics, and technical support."
                },
                para4:{
                    label: "When required by law:",
                    desc: "We may disclose your information if required by law or if we believe that such disclosure is necessary to protect our rights or comply with legal processes."
                },
                para5:{
                    label: "In connection with business transactions:",
                    desc: "In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction."
                },
            },
            subtitle4:{
                title: "4. Data Security",
                para1: "We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.",
            },
            subtitle5:{
                title: "5. Your Rights",
                para1: "You have the following rights concerning your personal information:",
                para2:{
                    label: "Access:",
                    desc: "You can request a copy of your personal information that we hold."
                },
                para3:{
                    label: "Correction:",
                    desc: "You can request correction of inaccurate or incomplete information."
                },
                para4:{
                    label: "Deletion:",
                    desc: "You can request deletion of your personal information under certain circumstances."
                },
                para5:{
                    label: "Restriction of Processing:",
                    desc: "You can request the restriction of processing of your personal information under certain circumstances."
                },
                para6:{
                    label: "Data Portability:",
                    desc: "You can request a copy of your personal information in a machine-readable and commonly used format."
                },
                para7:{
                    label: "Objection:",
                    desc: "You can object to the processing of your personal information under certain circumstances."
                }
            },
            subtitle6:{
                title: "6. Children’s Privacy",
                para1: "Our App is not intended for individuals under the age of 13. We do not knowingly collect personal information from individuals under 13. If we become aware that we have collected personal information from an individual under 13, we will take steps to delete such information.",
            },
            subtitle7:{
                title: "7. Cookies and Other Tracking Technologies",
                para1: "We use cookies and similar tracking technologies to collect usage information and improve user experience. Cookies are small files stored on your device that help us recognize you and remember your preferences. You can set your browser to refuse all cookies or to notify you when cookies are being sent. However, some features of the App may not function properly without cookies.",
            },
            subtitle8:{
                title: "8. Links to Third Party Sites",
                para1: "Our Application may contain links to third party sites or services that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party sites or services.",
            },
            subtitle9:{
                title: "9. Changes to This Privacy Policy",
                para1: "We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.",
            },
            subtitle10:{
                title: "10. Contact Us",
                para1: {
                    desc: "If you have any questions about this Privacy Policy, please contact us at:",
                    email: "Email:"
                },
                para2: "By using Enkrip, you agree to the terms of this Privacy Policy. If you do not agree to these terms, please do not use the Application."
            }
        },
        terms: {
            title: "{{APP_NAME}} Terms of Use",
            subtitle1: {
                title: "1. Introduction",
                para1: "Welcome to {{APP_NAME}}! These Terms of Use (“Terms”) govern your use of our mobile and web applications (“App”). By accessing or using the App, you agree to comply with these Terms. If you do not agree with these Terms, you must not use the App."
            },
            subtitle2: {
                title: "2. Acceptance of Terms",
                para1: "2.1. By registering, accessing, or using the App, you accept and agree to be bound by these Terms and our Privacy Policy.",
                para2: "2.2. If you are using the App on behalf of an organization, you represent and warrant that you have the authority to bind that organization to these Terms."
            },
            subtitle3: {
                title: "3. Modifications to Terms",
                para1: "3.1. {{APP_NAME}} reserves the right to update or modify these Terms at any time. We will notify you of any changes by posting the new Terms on our website and App or by email.",
                para2: "3.2. Your continued use of the App following any changes constitutes your acceptance of the revised Terms."
            },
            subtitle4: {
                title: "4. User Accounts",
                para1: "4.1. To access certain features of the App, you must create an account. You agree to provide accurate, current, and complete information during the registration process.",
                para2: "4.2. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
                para3: "4.3. You agree to notify us immediately of any unauthorized use of your account or any other breach of security."
            },
            subtitle5: {
                title: "5. Privacy Policy",
                para1: "5.1. Our Privacy Policy explains how we collect, use, and share your information. By using the App, you consent to the collection and use of your information as described in our Privacy Policy."
            },
            subtitle6: {
                title: "6. Use of the App",
                para1: "6.1. You agree to use the App in accordance with these Terms and all applicable laws and regulations.",
                para2: "6.2. You must not use the App to transmit any harmful or illegal content, including but not limited to, viruses, malware, or any content that infringes on the rights of others."
            },
            subtitle7: {
                title: "7. User Content",
                para1: "7.1. You retain ownership of any content you upload, post, or share through the App (“User Content”). By submitting User Content, you grant {{APP_NAME}} a worldwide, non-exclusive, royalty-free, transferable license to use, reproduce, distribute, and display such content in connection with the App.",
                para2: "7.2. You represent and warrant that you own or have the necessary rights to upload, post, or share User Content and that your User Content does not violate any third-party rights or applicable laws."
            },
            subtitle8: {
                title: "8. Prohibited Conduct",
                para1: "8.1 You agree not to engage in any of the following prohibited activities:",
                para2: "Using the App for any illegal purpose or to promote illegal activities.",
                para3: "Impersonating another person or entity or falsely stating your affiliation with a person or entity.",
                para4: "Interfering with or disrupting the security, integrity, or performance of the App.",
                para5: "Attempting to gain unauthorized access to the App or its related systems or networks."
            },
            subtitle9: {
                title: "9. Intellectual Property",
                para1: "9.1. The App and all content and materials included therein, such as text, graphics, logos, images, and software, are the property of {{APP_NAME}} or its licensors and are protected by intellectual property laws.",
                para2: "9.2. You are granted a limited, non-exclusive, non-transferable, revocable license to access and use the App for personal, non-commercial purposes.",
                para3: "9.3. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our App, except as permitted by these Terms."
            },
            subtitle10: {
                title: "10. Termination",
                para1: "10.1. {{APP_NAME}} reserves the right to terminate or suspend your access to the App at any time, without notice, for any reason, including if you breach these Terms.",
                para2: "10.2. Upon termination, your right to use the App will immediately cease, and you must destroy all copies of downloaded or obtained materials from the App."
            },
            subtitle11: {
                title: "11. Limitation of Liability",
                para1: "11.1. {{APP_NAME}} shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use of or inability to use the App.",
                para2: "11.2. {{APP_NAME}} makes no warranties or representations about the accuracy or completeness of the App’s content or the content of any websites linked to the App."
            },
            subtitle12: {
                title: "12. Indemnification",
                para1: "12.1. You agree to indemnify, defend, and hold harmless {{APP_NAME}}, its affiliates, officers, directors, employees, agents, and licensors from any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees arising out of your violation of these Terms or your use of the App."
            },
            subtitle13: {
                title: "13. Governing Law and Jurisdiction",
                para1: "13.1. These Terms shall be governed by and construed in accordance with the laws of Indonesia, without regard to its conflict of law principles.",
                para2: "13.2. Any legal suit, action, or proceeding arising out of or related to these Terms or the App shall be instituted exclusively in the courts of Indonesia."
            },
            subtitle14: {
                title: "14. Miscellaneous",
                para1: "14.1. These Terms constitute the entire agreement between you and {{APP_NAME}} regarding the use of the App.",
                para2: "14.2. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.",
                para3: "14.3. {{APP_NAME}}’s failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by {{APP_NAME}} in writing."
            },
            subtitle15: {
                title: "15. Contact Information",
                para1: {
                    desc: "15.1. If you have any questions or concerns about these Terms or the App, please contact us at"
                }
            }
        },
        contactUs: {
            title: "Contact Us",
            desc: "We're here to help! If you have any questions, feedback, or need assistance, please don't hesitate to reach out. You can contact us through any of the following methods:"
        },
        deleteAccount: {
            title: "Delete Account",
            desc: "Below are the steps for deleting an account,",
            step1: "Log in to your ENKRIP account on Mobile App",
            step2: "Go To 'Profile page' by tapping on profile picture (top left of the screen) > Settings (top right of the screen) > Delete My Account",
            step3: "Select a reason and Click \"Continue\"",
            step4: "Enter the Mobile number and OTP",
            step5: "In the Account Delete page, Click \"Continue\"",
            step6: "Account will be deleted immediately once the \"Delete\" button is clicked",
        }
    }
}