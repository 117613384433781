import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import "./Website/assets/scss/common.scss";
// import Home from 'Website/Pages/Home/Components/Home';
// import About from 'Website/Pages/About/Components/About';
// import Contact from 'Website/Pages/Contact/Components/Contact';
// import Privacy from 'Website/Pages/Privacy/Components/Privacy';
// import Terms from 'Website/Pages/Terms/Components/Terms';
import Layout from 'Website/Layout/Components/Layout';
import Launching from 'Website/Pages/Launching/Components/Launching';

const Home = React.lazy(() => import('Website/Pages/Home/Components/Home'));
const About = React.lazy(() => import('Website/Pages/About/Components/About'));
const Contact = React.lazy(() => import('Website/Pages/Contact/Components/Contact'));
const Privacy = React.lazy(() => import('Website/Pages/Privacy/Components/Privacy'));
const Terms = React.lazy(() => import('Website/Pages/Terms/Components/Terms'));
const DeleteAccount = React.lazy(() => import('Website/Pages/DeleteAccount/Components/DeleteAccount'));

function App() {
  return (
    <Router>
      <Layout>
        <Suspense fallback={<div className='preload'></div>}>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/delete_account" element={<DeleteAccount />} />
              <Route path="/launchingSoon" element={<Launching />} />
              <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </Layout>
    </Router>
  );
}

export default App;
